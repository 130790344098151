function phoneFormatting(ele) {
  const x = ele.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
  ele.value = !x[2] ? x[1] : x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');

  if (ele.value.length === 12) {
    document.getElementById('phone_box').classList.remove('border--red');
    document.getElementById('cell_box').classList.remove('border--red');
    document.getElementById('phone_error').innerHTML = '';
    document.getElementById('cell_error').innerHTML = '';
  }
}

function phoneNumberCheck(field, e) {
  const keyCode = e.keyCode;
  const keyValue = e.key;
  let pressDelete = false;
  const dashKey = 189;
  const deleteKey = [8, 46];
  const directionKey = [33, 34, 35, 36, 37, 38, 39, 40];
  const selectionEnd = field.selectionEnd;

  // delete key was pressed
  if (deleteKey.indexOf(keyCode) > -1) {
    pressDelete = true;
  }

  // only force formatting is a number or delete key was pressed
  if (keyValue.match(/^\d+$/) || pressDelete) {
    phoneFormatting(field, pressDelete);
  }
  // do nothing for direction keys, keep their default actions
  else if (directionKey.indexOf(keyCode) > -1) {
    // do nothing
  } else if (dashKey === keyCode) {
    if (selectionEnd === field.value.length) {
      field.value = field.value.slice(0, -1);
    } else {
      field.value = field.value.substring(0, selectionEnd - 1) + field.value.substr(selectionEnd);
      field.selectionEnd = selectionEnd - 1;
    }
  }
  // all other non numerical key presses, remove their value
  else {
    e.preventDefault();
    //    field.value = field.value.replace(/[^0-9\-]/g,'')
    phoneFormatting(field, 'revert');
  }
}

const inputValidationListener = () => {
  if (document.getElementById('phone')) {
    document.getElementById('phone').onkeyup = function (e) {
      phoneNumberCheck(this, e);
    };
  }
  if (document.getElementById('cell')) {
    document.getElementById('cell').onkeyup = function (e) {
      phoneNumberCheck(this, e);
    };
  }
};

export { inputValidationListener };
